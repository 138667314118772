
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import AreaSelect from "@/components/scope/area-select.vue";
import t from "@common/src/i18n/t";

@Component({
  components: { AreaSelect }
})
export default class FilterBar extends Vue {
  @Prop({
    default: () => []
  })
  data: any[];
  selfData: any[] = [];
  currentTime: any = {
    year: "",
    month: "",
    day: ""
  };
  created() {
    this.getCurrentTime();
    this.selfData = this.data.slice();
    this.selfData.forEach(item => {
      if (item.type === "select-view") {
        if (!item.value) {
          item.value = item.selectList[0].value;
        }
        if (item.selectList.every(v => v.itemValue === "")) {
          this.resetTimeValue();
        }
      }
    });
  }
  changeTiemValue(value, isSameYear) {
    if (isSameYear) {
      if (value[0].slice(0, 4) !== value[1].slice(0, 4)) {
        this.$message.warning(t("v210901.the-time-range-must-same-year"));
        this.resetTimeValue();
      }
    }
  }
  // 默认选择当前时间范围
  resetTimeValue() {
    let { year, month, day } = this.currentTime;
    this.selfData.forEach(item => {
      if (item.type === "select-view") {
        item.selectList.forEach(part => {
          if (item.value === part.value) {
            if (part.itemValueFormat === "yyyyMMdd") {
              part.itemValue = [year + month + "01", year + month + day];
            } else if (part.itemValueFormat === "yyyyMM") {
              part.itemValue = [year + "01", year + month];
            }
          }
        });
      }
    });
  }
  getCurrentTime() {
    // 获取前一天时间
    // let timestamp = new Date().getTime() - 24 * 60 * 60 * 1000;
    let timestamp = new Date().getTime();
    let currentTime = new Date(timestamp);
    this.currentTime.year = String(currentTime.getFullYear());
    this.currentTime.month = this.addZero(currentTime.getMonth() + 1);
    this.currentTime.day = this.addZero(currentTime.getDate());
  }
  addZero(num) {
    return num < 10 ? "0" + num : String(num);
  }
  search() {
    this.$emit("search", this.selfData);
  }
  reset() {
    this.selfData.forEach(item => {
      if (item.type === "select-view") {
        // 重置默认选第一个
        item.value = item.selectList[0].value;
        item.selectList.forEach(part => {
          if (Object.prototype.toString.call(part.itemValue) === "[object Array]") {
            part.itemValue = [];
          } else {
            part.itemValue = "";
          }
        });
      } else {
        item.value = "";
        item.labels = "";
      }
    });
    this.resetTimeValue();
    this.$emit("reset", this.selfData);
  }
  // 切换选项卡 重置数据
  handleSelectChange(row) {
    let { year, month, day } = this.currentTime;
    this.selfData.forEach(item => {
      if (item.prop === row.prop) {
        item.selectList.forEach(part => {
          if (Object.prototype.toString.call(part.itemValue) === "[object Array]") {
            part.itemValue = [];
          } else {
            part.itemValue = "";
          }
          if (item.value === part.value) {
            if (part.itemValueFormat === "yyyyMMdd") {
              part.itemValue = [year + month + "01", year + month + day];
            } else if (part.itemValueFormat === "yyyyMM") {
              part.itemValue = [year + "01", year + month];
            }
          }
        });
      }
    });
  }
  handleAreaChange(val, item) {
    this.$nextTick(() => {
      this.$nextTick(() => {
        const labels = (this.$refs["refAareSelect"] as any)[0].getLabels();
        item.labels = labels;
      });
    });
  }
  @Watch("data")
  watchData(val) {
    this.selfData = val.slice();
    this.selfData.forEach(item => {
      if (item.type === "select-view") {
        if (!item.value) {
          item.value = item.selectList[0].value;
        }
        if (item.selectList.every(v => v.itemValue === "")) {
          this.resetTimeValue();
        }
      }
    });
    this.selfData.forEach((item, index) => {
      if (item.type === "select-search") {
        item.selectList = val[index].selectList;
      }
      if (item.type === "select-view") {
        item.selectList.forEach((v, i) => {
          v.itemSelectList = val[index].selectList[i].itemSelectList;
        });
      }
    });
  }
}
