
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
const app = namespace("app");
const base = namespace("base");
@Component({
  inheritAttrs: false
})
export default class AreaSelect extends Vue {
  @Prop({ default: false })
  anyLevel: boolean;
  @base.Action getAreaList;
  props: any = {
    lazy: true,
    checkStrictly: false,
    label: "areaName",
    value: "areaCode",
    lazyLoad: async (node, resolve) => {
      const { level, data: nodeData } = node;
      if (level === 0) {
        const data = await this.getAreaList();
        resolve(data.data.list);
      } else {
        const data = await this.getAreaList({ parentAreaCode: nodeData.areaCode });
        let list = data.data.list;
        if (level === 2) {
          list = data.data.list.map(item => {
            item.leaf = true;
            return item;
          });
        }
        resolve(list);
      }
    }
  };
  created() {
    this.props.checkStrictly = this.anyLevel || false;
  }
  getLabels() {
    let str = (this.$refs["com-area-select"] as any).querySelector(".com-area-select .el-cascader .el-input__inner")
      .value;
    console.log(str);
    return str.split(" / ");
  }
}
