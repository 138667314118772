
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";

@Component({})
export default class BtnRadio extends Vue {
  @Prop()
  options: any;
  @Prop()
  value: string;
  get $value() {
    return this.value;
  }
  prevTimeStamp = Date.now();
  handleItemClick(item) {
    let _this = this;
    if (Date.now() - _this.prevTimeStamp > 100) {
      _this.prevTimeStamp = Date.now();
      console.log(111111111);
      _this.$emit("update:value", item.value);
      _this.$emit("change", item.value);
    }
  }
}
